@use '../node_modules/swiper/swiper.scss' as swiper;


// Import themes which define the css variables we use to customize the app
@import './theme/themes/dark';

// Import colors for overrides of bootstrap theme
@import './theme/toastr';
@import './theme/variables';

// Bootstrap must be after _colors since we define the colors there
@import '~bootstrap/scss/bootstrap';


// Import all the customized theme overrides
@import './theme/components/input';
@import './theme/components/anchors';
@import './theme/components/nav';
@import './theme/components/buttons';
@import './theme/components/toast';
@import './theme/components/checkbox';
@import './theme/components/list';
@import './theme/components/navbar';
@import './theme/components/popover';
@import './theme/components/pagination';
@import './theme/components/dropdown';
@import './theme/components/accordion';
@import './theme/components/breadcrumb';
@import './theme/components/ratingstar';
@import './theme/components/modal';
@import './theme/components/card';
@import './theme/components/slider';
@import './theme/components/radios';
@import './theme/components/selects';
@import './theme/components/progress';
@import './theme/components/sidenav';
@import './theme/components/carousel';
@import './theme/components/offcanvas';


@import './theme/utilities/utilities';
@import './theme/utilities/animations';
@import './theme/utilities/global';


// Global Styles
@font-face {
  font-family: "EBGarmond";
  src: url("assets/fonts/EBGarmond/EBGaramond-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Spartan";
  src: url("assets/fonts/Spartan/Spartan-VariableFont_wght.ttf");
}

label, select, .clickable {
  cursor: pointer;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
}

// Needed for fullscreen
app-root {
  background-color: transparent;
  scrollbar-width: 14px;
  scrollbar-color: var(--primary-color-scrollbar);
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: var(--primary-color-scrollbar);
  border: 3px solid transparent;
  border-radius: 8px;
  min-height: 50px;
}
